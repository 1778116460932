import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image'

const Bios = ({ heading, people = []}) => (
  <div className="bg-white">
    <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
      <div className="space-y-12">
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
          {heading}
        </h2>
        <ul className="grid md:grid-cols-2 xl:grid-cols-3 xl:items-start xl:gap-x-8  xl:space-y-0">
          {people.map((person) => (
        <div className="pb-12 mx-auto group text-lg font-medium"> 
            <li key={person.name}>
              <div className="space-y-4 sm:gap-6 sm:space-y-0 lg:gap-8 mx-auto text-center">
                { person.photo ? <GatsbyImage Tag="div" className="shadow-lg round-lg" image={person.photo.gatsbyImageData} /> : <div className="pt-60"></div>}
                <div className="sm:col-span-2 text-center">
                  <div className="space-y-4">
                      <h3>{person.name}</h3>
                      <p className="text-highlight">{person.title}</p>
                      <p className="text-sm">Read bio</p>
                      <div className="z-10 px-2.5 lg:w-1/4 ease-in-out text-md absolute hidden group-hover:flex bg-gray-300 rounded-lg text-sm text-left space-y-3">
                        {person.bio.bio}
                      </div>
                    {/*
                    <div className="text-gray-500 text-md">
                      {person.bio}
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default Bios;
